export default {
  blogerName: 'CELLINRJ',
  socialsList: [
    {
      name: 'x',
      url: 'https://twitter.com/cellinrj',
    },
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/cellinrj',
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/cellinrj/',
    },
    {
      name: 'telegram',
      url: 'https://t.me/cellincassinos',
    }
  ],
  projects: [
    {
      name: 'starda',
      url: 'https://stardacasino.life/cdd93b20e',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/cf94d18f3',
      gameTitle: 'Alien Fruits (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c2720437a',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/c915a944d',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/c8dc1e52e',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c094898a0',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/c6ca7f19d',
      gameTitle: 'Candy Monsta (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>CELLINRJ</strong> e receba 125 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'CELLINRJ',
  casinoBonus1: '<strong>100% + 500FS</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>125FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'dark'
}
